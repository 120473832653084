<template>
  <div class="lightbox-container">
    <div class="title-container">
      <div
        v-if="title"
        class="wire-title"
      >
        {{ title }}
      </div>
      <div
        v-if="currentImage.legend && currentImage.legend.length > 0"
        class="legend"
        @click="toggleLightBox()"
      >
        <div class="legend-row">
          Legend
          <div
            @click="toggleLightBox()"
            class="icon"
          />
        </div>
      </div>
    </div>
    <div>
      <img
        class="figure-image"
        :src="imgUrl"
        :alt="imgAlt"
        @click="toggleLightBox()"
        :id="`figure-${this.index}`"
        @load="scrollToFigure"
      >
    </div>
    <ImageOverlay
      v-if="showOverlay"
      :current-image="currentImage"
      @closing="closeLightbox"
      @swipe-left="swipeLeft"
      @swipe-right="swipeRight"
    />
  </div>
</template>

<script>
import ImageOverlay from './ImageOverlay.vue';

export default {
  name: 'ImageWithLightbox',
  components: { ImageOverlay },
  props: {
    index: {
      type: Number,
      required: true,
    },
    otherImages: {
      type: Array,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    imgAlt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    activeFigure: {
      type: Number,
    },
  },
  data() {
    return {
      showOverlay: false,
      currentIndex: this.index,
    };
  },
  computed: {
    currentImage() {
      const current = this.otherImages[this.currentIndex];
      return {
        url: current.url,
        alt: current.title || current.label,
        title: current.title || current.label,
        legend: current.legend,
      };
    },
  },
  methods: {
    closeLightbox() {
      this.showOverlay = false;
    },
    toggleLightBox() {
      this.showOverlay = true;
    },
    swipeRight() {
      if (this.currentIndex !== this.otherImages.length - 1) {
        this.currentIndex += 1;
      }
    },
    swipeLeft() {
      if (this.currentIndex !== 0) {
        this.currentIndex -= 1;
      }
    },
    scrollToFigure() {
      if (this.activeFigure === this.currentIndex + 1) {
        const figure = document.getElementById(`figure-${this.currentIndex + 1}`);
        if (figure) {
          figure.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    },
  },
};
</script>

<style lang="scss">

.lightbox-container {
  .figure-image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
  }

  .wire-title {
    color: $text-secondary;
  }

  .title-container {
    display: flex;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    margin: 8px 24px 8px 0;
  }

  .legend {
    margin-left: auto;
    color: $text-secondary;
  }

  .legend-row {
    display: flex;
    justify-content: center;
  }

  .icon {
    content: "";
    width: 14px;
    height: 14px;
    margin-left: 8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
  }
}
</style>
