<template>
  <img
    class="icon"
    src="../assets/yt_icon_rgb.png"
    alt="Youtube"
    @click.prevent="onClick"
  >
</template>

<script>
export default {
  name: 'YouTubeButton',
  props: {
    buttonReference: {
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$native.send({
        event: 'youtube-button:play',
        name: this.buttonReference,
      });
    },
  },
};
</script>

<style scoped>
.icon {
  height: 30px;
  width: 40px;
  margin-left: auto;
}
</style>
