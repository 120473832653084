import { render, staticRenderFns } from "./SummarySectionLink.vue?vue&type=template&id=545b1443&scoped=true"
import script from "./SummarySectionLink.vue?vue&type=script&lang=js"
export * from "./SummarySectionLink.vue?vue&type=script&lang=js"
import style0 from "./SummarySectionLink.vue?vue&type=style&index=0&id=545b1443&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545b1443",
  null
  
)

export default component.exports